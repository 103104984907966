.top_sec {
  height: 30rem;
  background-size: cover;
  background-position: top;
  background-blend-mode: color;
  background-color: rgba(0, 0, 0, 0.5);
}

.item {
  transition: transform 250ms ease-in-out 50ms;
}

.item:hover {
  transform: scale(1.25) !important;
}
